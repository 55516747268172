<template>
  <v-card class="b_tabs__items__card">
    <div class="b_tabs__items__card--text">
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            class="b-table-invoice no-border"
            disable-sort
            hide-default-footer
            dense
            item-key="id"
            :headers="headers"
            :items="model.unifiedInvoiceResumes"
          >
            <template v-slot:[`item.invoiceValue`]="{ item }">
              {{ formatMoney(item.invoiceValue) }}
            </template>

            <template v-slot:[`body.append`]>
              <tr class="b-table-invoice__footer">
                <td>Total de vidas e valor</td>
                <td>{{ model.amountLives }}</td>
                <td class="success--text">
                  {{ formatMoney(model.totalInvoicesValue) }}
                </td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <v-card-actions class="b_tabs__items__card--action">
      <v-spacer />
      <BaseButton color="primary" title="Exportar" @click="execute" />
    </v-card-actions>
  </v-card>
</template>

<script>
import { formatMoney } from '@/helpers/formatting';
import { mapGetters } from 'vuex';
import { downloadFile } from '@/helpers/download';
import { InvoiceService } from '@/services/api/invoice';

export default {
  data: () => ({
    model: {},
    valid: true,
    headers: [
      { text: 'Cliente', value: 'customerName' },
      { text: 'Vidas', value: 'amountLives' },
      { text: 'Valor', value: 'invoiceValue' },
      { text: 'Status', value: 'situation' }
    ]
  }),

  computed: {
    ...mapGetters({
      invoice: 'invoice/getInvoice'
    })
  },

  created() {
    this.search();
  },

  methods: {
    formatMoney,

    async search() {
      try {
        const invoiceService = new InvoiceService();
        const { status, data } = await invoiceService.getUnifiedInvoices({
          invoiceId: this.invoice.id
        });

        if (status === 200) {
          this.model = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async execute() {
      try {
        const invoiceService = new InvoiceService();
        const { status, data } = await invoiceService.downloadUnifiedInvoice({
          invoiceId: this.invoice.id
        });

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
